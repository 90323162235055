import { Flex, Heading, Link } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/layout';
import { Collapse } from '@chakra-ui/transition';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReadMoreBtn from './ReadMoreBtn';
/* import gif from '../images/11-2022/methoden/methodenclip.gif'; */

const Competencies = ({ ...props }, ref) => {
  const { t } = useTranslation('competencies');
  const query = useStaticQuery(graphql`
    query {
      retorikImg: file(
        relativePath: { eq: "dmitry-ratushny-O33IVNPb0RI-unsplash.jpg" }
      ) {
        ...Image
      }
      coachingImg: file(
        relativePath: { eq: "seth-doyle-3rcT6_NjjbU-unsplash.jpg" }
      ) {
        ...Image
      }
      coaching2Img: file(relativePath: { eq: "04-2022/coaching2.svg" }) {
        publicURL
      }
      redenImg: file(
        relativePath: { eq: "florian-klauer-mk7D-4UCfmg-unsplash.jpg" }
      ) {
        ...Image
      }
      moderationImg: file(relativePath: { eq: "Carl.jpg" }) {
        ...Image
      }
      workshopsImg: file(
        relativePath: { eq: "ross-findon-mG28olYFgHI-unsplash.jpg" }
      ) {
        ...Image
      }
      nineL: file(relativePath: { eq: "11-2022/methoden/9L-Certified.png" }) {
        ...Image
      }
      bdtv: file(relativePath: { eq: "11-2022/methoden/BDVT.tiff" }) {
        ...Image
      }
      bfb: file(relativePath: { eq: "11-2022/methoden/bfB.jpg" }) {
        ...Image
      }
      firoB: file(relativePath: { eq: "11-2022/methoden/firoB.jpeg" }) {
        ...Image
      }
      kulturkisten: file(
        relativePath: { eq: "11-2022/methoden/kulturkisten.png" }
      ) {
        ...Image
      }
      liberatingStructure: file(
        relativePath: { eq: "11-2022/methoden/liberatingStructure.jpg" }
      ) {
        ...Image
      }
      lspTm: file(relativePath: { eq: "11-2022/methoden/lspTm.jpg" }) {
        ...Image
      }
      mbti: file(relativePath: { eq: "11-2022/methoden/mbtiCertified.png" }) {
        ...Image
      }
      rmp: file(relativePath: { eq: "11-2022/methoden/rmp.jpeg" }) {
        ...Image
      }
      scil: file(relativePath: { eq: "11-2022/methoden/scilProfile.png" }) {
        ...Image
      }
      tbt: file(relativePath: { eq: "11-2022/methoden/tbt.jpg" }) {
        ...Image
      }
      teamSing: file(relativePath: { eq: "06-2023/TeamSing.jpg" }) {
        ...Image
      }
      braintoframe: file(relativePath: { eq: "06-2023/braintoframe.webp" }) {
        ...Image
      }
      brainforbusiness: file(relativePath: { eq: "03-2024/BfB.jpg" }) {
        ...Image
      }
    }
  `);
  const {
    retorikImg,
    coachingImg,
    redenImg,
    moderationImg,
    coaching2Img,
    workshopsImg,
    nineL,
    bdtv,
    bfb,
    firoB,
    kulturkisten,
    liberatingStructure,
    lspTm,
    mbti,
    rmp,
    scil,
    tbt,
    braintoframe,
    teamSing,
    brainforbusiness,
  } = query;
  const blocks = [
    {
      id: 'coaching',
      blockName: t('coaching.name'),
      heading: t('coaching.heading'),
      text: t('coaching.text'),
      image: getImage(coachingImg),
      textHeading: t('coaching.textHeading'),
      subheading: t('coaching.textSubheading'),
      expandText: t('coaching.expandText'),
    },
    {
      id: 'coaching2',
      notShowInHeader: true,
      blockName: t('coaching2.name'),
      heading: t('coaching2.heading'),
      svg: true,
      text: t('coaching2.text'),
      image: coaching2Img,
      textHeading: t('coaching2.textHeading'),
      subheading: t('coaching2.textSubheading'),
      expandText: t('coaching2.expandText'),
    },
    {
      id: 'workshops',
      blockName: t('workshops.name'),
      heading: t('workshops.heading'),
      text: t('workshops.text'),
      image: getImage(workshopsImg),
      textHeading: t('workshops.textHeading'),
      subheading: t('workshops.textSubheading'),
      expandText: t('workshops.expandText'),
    },
    {
      // Content Creation
      id: 'retorik',
      blockName: t('retorik.name'),
      heading: t('retorik.heading'),
      text: t('retorik.text'),
      image: getImage(retorikImg),
      textHeading: t('retorik.textHeading'),
      subheading: t('retorik.textSubheading'),
      expandText: t('retorik.expandText'),
    },
    {
      // Interne Kommuniktaioon
      id: 'reden',
      blockName: t('reden.name'),
      heading: t('reden.heading'),
      text: t('reden.text'),
      image: getImage(redenImg),
      textHeading: t('reden.textHeading'),
      subheading: t('reden.textSubheading'),
      expandText: t('reden.expandText'),
      notShowInHeader: true,
    },
    /* {
      id: 'moderation',
      blockName: t('moderation.name'),
      heading: t('moderation.heading'),
      text: t('moderation.text'),
      image: getImage(moderationImg),
      textHeading: t('moderation.textHeading'),
      subheading: t('moderation.textSubheading'),
      expandText: t('moderation.expandText'),
    }, */
  ];
  const refs = useRef({});
  const [isOpen, setIsOpen] = useState(blocks.map(e => false));
  const onToggle = i => () => {
    const newData = { ...isOpen };
    newData[i] = !newData[i];
    setIsOpen(newData);
  };

  const navbarItems = [
    ...blocks.filter(e => !e.notShowInHeader),
    { id: 'downloads', blockName: t('downloads.name') },
    { id: 'methoden', blockName: t('methoden.name') },
  ];

  const NavBar = () => (
    <Flex
      as="nav"
      w="100vw"
      h={['auto', '80px']}
      justifyContent="center"
      alignItems="center"
      flexDir={['column', 'row']}
      my={['10px', 0]}
    >
      {navbarItems.map((e, i) => (
        <Link
          key={e.id}
          mx="20px"
          fontSize={['18px', '20px']}
          textAlign="center"
          textDecor={['underline', 'none']}
          fontWeight="600"
          href={`#${e.id}`}
          onClick={a => {
            a.preventDefault();
            refs.current[e.id].scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            });
          }}
          my={['10px', 0]}
        >
          {e.blockName}
        </Link>
      ))}
    </Flex>
  );

  refs.current['downloads'] = props.downloadRef;

  return (
    <section ref={ref} {...props}>
      {blocks.map((e, i) => (
        <React.Fragment key={e.id}>
          <NavBar />
          <Flex
            overflow="hidden"
            minH={['auto', '100vh']}
            w="100vw"
            ref={el => (refs.current[e.id] = el)}
            as="section"
            flexDirection={['column', i % 2 ? 'row' : 'row-reverse']}
            justifyContent={['flex-end', 'flex-start']}
            pos="relative"
          >
            <Flex
              pos={['relative', 'absolute']}
              top="0"
              bottom="0"
              w="100%"
              height={['300px', 'auto']}
              zIndex={0}
              justifyContent="flex-end"
              flexDir="column"
            >
              <Box
                pos="absolute"
                top="0"
                bottom="0"
                width="100%"
                h="100%"
                zIndex={0}
              >
                {!e.svg && (
                  <GatsbyImage
                    image={e.image}
                    alt="Header"
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                    objectFit="cover"
                  />
                )}
                {e.svg && e.image && (
                  <object
                    type="image/svg+xml"
                    style={{ height: '100%', width: '100%' }}
                    data={e.image.publicURL}
                  />
                )}
              </Box>
              <Box
                pos="relative"
                zIndex={2}
                display={['block', 'none']}
                bgColor="brand.300"
                px="10px"
                py="15px"
              >
                <Heading>{e.heading}</Heading>
                {e.blockName && (
                  <Text
                    textTransform="uppercase"
                    fontSize="22px"
                    fontWeight="600"
                    dangerouslySetInnerHTML={{ __html: e.blockName }}
                  />
                )}
              </Box>
            </Flex>
            <Box
              zIndex={10}
              as="article"
              w={['100%', '50%']}
              minH={['auto', '100%']}
              bgColor={['brand.400', 'brand.300']}
              pt={['10px', '40px']}
              pb={['10px', '40px']}
              px={['10px', '40px']}
              fontSize={['16px', '20px']}
            >
              <Heading
                as="h3"
                fontSize="46px"
                color="brand.600"
                dangerouslySetInnerHTML={{ __html: e.textHeading }}
              />
              {e.subheading && (
                <Text
                  mb="20px"
                  textTransform="uppercase"
                  fontSize="22px"
                  fontWeight="600"
                  dangerouslySetInnerHTML={{ __html: e.subheading }}
                />
              )}
              <Text dangerouslySetInnerHTML={{ __html: e.text }} />
              {e.expandText && (
                <Box>
                  <ReadMoreBtn isOpen={isOpen[i]} onToggle={onToggle(i)} />
                  <Collapse
                    in={isOpen[i]}
                    dangerouslySetInnerHTML={{ __html: e.expandText }}
                  />
                </Box>
              )}
            </Box>
            <Flex
              zIndex={10}
              w="50%"
              minH="100%"
              display={['none', 'flex']}
              pos="relative"
              flexFlow={i % 2 ? 'row-reverse' : 'row'}
              alignItems="center"
            >
              <Box
                p="10px"
                pos="relative"
                pl="40px"
                bgColor="rgba(0,0,0,0.55)"
                minW="450px"
                color="brand.400"
              >
                <Box
                  pos="absolute"
                  top="5px"
                  bottom="5px"
                  left="15px"
                  bg="brand.600"
                  w={'11px'}
                  style={{ content: '' }}
                />
                <Heading fontSize="70px">{e.heading}</Heading>
                <Text fontSize="30px" fontWeight={600} color="brand.800">
                  {e.blockName}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </React.Fragment>
      ))}
      <Flex
        as="article"
        align="center"
        flexDir="column"
        ref={el => (refs.current['methoden'] = el)}
        py="4rem"
      >
        <Heading mb="4rem" as="h2" size="2xl" color="brand.600">
          {t('methoden.name')}
        </Heading>
        <Flex
          w={['100%', '80%']}
          justify="center"
          flexWrap="wrap"
          align="center"
        >
          <Box w="200px" mx="1rem" mb="1rem">
            <Link href="https://www.9levels.de" target="__blank">
              <GatsbyImage
                image={getImage(nineL) as IGatsbyImageData}
                alt="Header"
                style={{
                  height: '100%',
                  width: '100%',
                }}
                objectFit="contain"
                objectPosition="center"
              />
            </Link>
          </Box>
          {/* <Box w="200px" mx="1rem" mb="1rem">
            <Link href="https://www.bdvt.de" target="__blank">
              <GatsbyImage
                image={getImage(bdtv) as IGatsbyImageData}
                alt="Header"
                style={{
                  height: '100%',
                  width: '100%',
                }}
                objectFit="contain"
                objectPosition="center"
              />
            </Link>
          </Box> */}
          <Box w="200px" mx="1rem" mb="1rem">
            <Link href="https://www.brain-to-frame.de/" target="__blank">
              <GatsbyImage
                image={getImage(braintoframe) as IGatsbyImageData}
                alt="Header"
                style={{
                  height: '100%',
                  width: '100%',
                }}
                objectFit="contain"
                objectPosition="center"
              />
            </Link>
          </Box>
          <Box w="200px" mx="1rem" mb="1rem">
            <Link href="https://liberatingstructures.de/" target="__blank">
              <GatsbyImage
                image={getImage(liberatingStructure) as IGatsbyImageData}
                alt="Header"
                style={{
                  height: '100%',
                  width: '100%',
                }}
                objectFit="contain"
                objectPosition="center"
              />
            </Link>
          </Box>
          <Box w="200px" mx="1rem" mb="1rem">
            {/* <Link href="#" target="__blank"> */}
            <GatsbyImage
              image={getImage(lspTm) as IGatsbyImageData}
              alt="Header"
              style={{
                height: '100%',
                width: '100%',
              }}
              objectFit="contain"
              objectPosition="center"
            />
            {/* </Link> */}
          </Box>
          <Box w="200px" mx="1rem" mb="1rem">
            <Link
              href="https://eu.themyersbriggs.com/de-DE/tools/MBTI"
              target="__blank"
            >
              <GatsbyImage
                image={getImage(mbti) as IGatsbyImageData}
                alt="Header"
                style={{
                  height: '100%',
                  width: '100%',
                }}
                objectFit="contain"
                objectPosition="center"
              />
            </Link>
          </Box>
          {/* <Box w="200px" mx="1rem" mb="1rem">
            <Link href="" target="__blank">
              <img src={gif} alt="Methodenclip" />
            </Link>
          </Box> */}
          <Box w="200px" mx="1rem" mb="1rem">
            <Link href="https://www.brainforbusiness.de/" target="__blank">
              <GatsbyImage
                image={getImage(brainforbusiness) as IGatsbyImageData}
                alt="Header"
                style={{
                  height: '100%',
                  width: '100%',
                }}
                objectFit="contain"
                objectPosition="center"
              />
            </Link>
          </Box>
          <Box w="200px" mx="1rem" mb="1rem">
            <Link href="https://www.rmp.eu" target="__blank">
              <GatsbyImage
                image={getImage(rmp) as IGatsbyImageData}
                alt="Header"
                style={{
                  height: '100%',
                  width: '100%',
                }}
                objectFit="contain"
                objectPosition="center"
              />
            </Link>
          </Box>
          <Box w="200px" mx="1rem" mb="1rem">
            <Link href="https://scil-profile.de" target="__blank">
              <GatsbyImage
                image={getImage(scil) as IGatsbyImageData}
                alt="Header"
                style={{
                  height: '100%',
                  width: '100%',
                }}
                objectFit="contain"
                objectPosition="center"
              />
            </Link>
          </Box>
          <Box w="200px" mx="1rem" mb="1rem">
            <Link href="http://www.teamsing.de/" target="__blank">
              <GatsbyImage
                image={getImage(teamSing) as IGatsbyImageData}
                alt="Header"
                style={{
                  height: '100%',
                  width: '100%',
                }}
                objectFit="contain"
                objectPosition="center"
              />
            </Link>
          </Box>
        </Flex>
      </Flex>
    </section>
  );
};

export default forwardRef(Competencies);
